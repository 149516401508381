<template>
  <v-avatar>
    <template v-if="lock">
      <div class="preAvatar memberLock">
        <i class="ri-lock-fill"></i>
      </div>
    </template>

    <template v-else>
      <v-img v-if="avatar" :src="avatar"></v-img>
      <div v-if="!avatar">
        <div class="preAvatar">
          <i class="ri-user-line"></i>
        </div>
      </div>
    </template>
  </v-avatar>
</template>

<script>
export default {
  props: {
    avatar: String,
    lock: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass">
.v-avatar
  border: 1px solid #f8f8f8
.preAvatar
  width: 48px
  height: 48px
  background: #cdcdcd
  text-align: center
  line-height: 48px
  font-size: 22px
  color: #ffffff
  &.memberLock
    color: #006DE0
    background: #D2E8FF
</style>